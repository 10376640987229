import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cn from 'classnames';
import styles from './styles';

const Loader = ({ classes, className, ...rest }) => {
  const rootStyles = cn([classes.root], className);
  return (
    <div className={rootStyles} data-node="loader">
      <CircularProgress color="primary" {...rest} />
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Loader);
